import PhotoSwipeLightbox from 'photoswipe/lightbox';

export class Lightbox {

	constructor() {
		this.galleries = document.querySelectorAll('[data-lightbox-gallery]');

		this.options = {
			// gallery: '#my-gallery',
			children: '[data-lightbox-item]',
			wheelToZoom: true,
			padding: {
				top: 40,
				bottom: 40,
				left: 100,
				right: 100
			},
			bgOpacity: 0.5,
			pswpModule: () => import('photoswipe')
		};

		if (!this.galleries.length) {
			return;
		}

		this.init();
	}

	init() {
		this.galleries.forEach(element => {
			this.options.gallery = element;

			let lightbox = new PhotoSwipeLightbox(this.options);
			
			lightbox.init();
		});
	}
}

new Lightbox;