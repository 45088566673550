/*
     _ _      _       _
 ___| (_) ___| | __  (_)___
/ __| | |/ __| |/ /  | / __|
\__ \ | | (__|   < _ | \__ \
|___/_|_|\___|_|\_(_)/ |___/
                   |__/

  Author: Ken Wheeler
 Website: http://kenwheeler.github.io
    Docs: http://kenwheeler.github.io/slick
    Repo: http://github.com/kenwheeler/slick
  Issues: http://github.com/kenwheeler/slick/issues

 */
import $ from 'jquery';

require('slick-carousel');

export class SlickInit {
	constructor() {

		this.items = $('.carousel').not(".slick-initialized");

		if (this.items.length) {
			this.init();
		}
	}

	init() {
		this.items.each(function () {
			var el = $(this);

			var slidesPerViewXs = el.data("xs-items");
			var slidesPerViewSm = el.data("sm-items");
			var slidesPerViewMd = el.data("md-items");
			var slidesPerViewLg = el.data("lg-items");
			var slidesPerViewXl = el.data("xl-items");
			var slidesPerView = el.data("items");

			var slidesCenterMode = el.data("center");
			var slidesArrows = el.data("arrows");
			var slidesDots = el.data("dots");
			var slidesRows = el.data("rows");
			var slidesAutoplay = el.data("autoplay");
			var slidesFade = el.data("fade");
			var asNavFor = el.data("nav-for");
			var infinite = el.data("infinite");
			var focusOnSelect = el.data("focus-select");
			var adaptiveHeight = el.data("auto-height");


			var vertical = el.data("vertical");
			var verticalXs = el.data("vertical-xs");
			var verticalSm = el.data("vertical-sm");
			var verticalMd = el.data("vertical-md");
			var verticalLg = el.data("vertical-lg");
			var verticalXl = el.data("vertical-xl");

			slidesPerView = !slidesPerView ? 1 : slidesPerView;
			slidesPerViewXl = !slidesPerViewXl ? slidesPerView : slidesPerViewXl;
			slidesPerViewLg = !slidesPerViewLg ? slidesPerViewXl : slidesPerViewLg;
			slidesPerViewMd = !slidesPerViewMd ? slidesPerViewLg : slidesPerViewMd;
			slidesPerViewSm = !slidesPerViewSm ? slidesPerViewMd : slidesPerViewSm;
			slidesPerViewXs = !slidesPerViewXs ? slidesPerViewSm : slidesPerViewXs;


			vertical = !vertical ? false : vertical;
			verticalXl = (typeof verticalXl == 'undefined') ? vertical : verticalXl;
			verticalLg = (typeof verticalLg == 'undefined') ? verticalXl : verticalLg;
			verticalMd = (typeof verticalMd == 'undefined') ? verticalLg : verticalMd;
			verticalSm = (typeof verticalSm == 'undefined') ? verticalMd : verticalSm;
			verticalXs = (typeof verticalXs == 'undefined') ? verticalSm : verticalXs;


			slidesCenterMode = !slidesCenterMode ? false : slidesCenterMode;
			slidesArrows = !slidesArrows ? false : slidesArrows;
			slidesDots = !slidesDots ? false : slidesDots;
			slidesRows = !slidesRows ? 1 : slidesRows;
			slidesAutoplay = !slidesAutoplay ? false : slidesAutoplay;
			slidesFade = !slidesFade ? false : slidesFade;
			asNavFor = !asNavFor ? null : asNavFor;
			infinite = !infinite ? false : infinite;
			focusOnSelect = !focusOnSelect ? false : focusOnSelect;
			adaptiveHeight = !adaptiveHeight ? false : adaptiveHeight;


			var slidesRtl = ($("html").attr("dir") === "rtl" && !vertical) ? true : false;
			var slidesRtlXL = ($("html").attr("dir") === "rtl" && !verticalXl) ? true : false;
			var slidesRtlLg = ($("html").attr("dir") === "rtl" && !verticalLg) ? true : false;
			var slidesRtlMd = ($("html").attr("dir") === "rtl" && !verticalMd) ? true : false;
			var slidesRtlSm = ($("html").attr("dir") === "rtl" && !verticalSm) ? true : false;
			var slidesRtlXs = ($("html").attr("dir") === "rtl" && !verticalXs) ? true : false;

			el.slick({
				slidesToShow: slidesPerView,
				autoplay: slidesAutoplay,
				dots: slidesDots,
				arrows: slidesArrows,
				infinite: infinite,
				vertical: vertical,
				rtl: slidesRtl,
				rows: slidesRows,
				centerPadding: "0px",
				centerMode: slidesCenterMode,
				fade: slidesFade,
				asNavFor: asNavFor,
				focusOnSelect: focusOnSelect,
				adaptiveHeight: adaptiveHeight,
				slidesToScroll: 1,
				prevArrow:
					'<button type="button" class="slick-prev"><i class="las la-angle-left"></i></button>',
				nextArrow:
					'<button type="button" class="slick-next"><i class="las la-angle-right"></i></button>',
				responsive: [
					{
						breakpoint: 1500,
						settings: {
							slidesToShow: slidesPerViewXl,
							vertical: verticalXl,
							rtl: slidesRtlXL,
						},
					},
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: slidesPerViewLg,
							vertical: verticalLg,
							rtl: slidesRtlLg,
						},
					},
					{
						breakpoint: 992,
						settings: {
							slidesToShow: slidesPerViewMd,
							vertical: verticalMd,
							rtl: slidesRtlMd,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: slidesPerViewSm,
							vertical: verticalSm,
							rtl: slidesRtlSm,
						},
					},
					{
						breakpoint: 576,
						settings: {
							slidesToShow: slidesPerViewXs,
							vertical: verticalXs,
							rtl: slidesRtlXs,
						},
					},
				],
			});
		});

	}
}

new SlickInit;

window.SlickInit = SlickInit;